<template>
  <el-form
    :model="reportForm"
    ref="formedForm"
    label-position="top"
    label-width="120px"
  >
    <h2>Внутренний отчет</h2>
    <el-row :gutter="10">
      <el-form-item prop="Date" label="Период">
        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd"
            v-model="reportForm.startDate"
            type="date"
          >
          </el-date-picker>
        </el-col>

        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd"
            v-model="reportForm.endDate"
            type="date"
          >
          </el-date-picker>
        </el-col>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="Выберите субъект">
        <el-tree
          :data="organization"
          show-checkbox
          node-key="id"
          ref="treeOrganization"
          :props="defaultProps"
          style="font-weight: bold"
        >
        </el-tree>
      </el-form-item>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="8">
        <el-button
          type="primary"
          @click="openAddWasteModal"
          :disabled="reportForm.startDate == '' || reportForm.endDate == ''"
          >Добавить отход</el-button
        >
      </el-col>
      <el-col :span="8">
        <el-checkbox v-model="reportForm.AllWaste">Все отходы</el-checkbox>
      </el-col>
    </el-row>
    <el-table stripe :data="reportForm.wastes" v-if="isVisible">
      <el-table-column
        label="Наименование отхода"
        prop="wasteName"
      ></el-table-column>
      <el-table-column label="Код отхода" prop="wasteCode"></el-table-column>
      <el-table-column
        label="Степень опасности"
        prop="dangerDegreeClassifierDTO.name"
      ></el-table-column>
      <el-table-column>
        <template slot-scope="item">
          <el-button
            @click="dropLine(item.$index)"
            type="primary"
            style="float: left"
            >Удалить
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-form-item label="Все движения">
        <el-tree
          :data="actions"
          show-checkbox
          node-key="id"
          ref="treeActions"
          :props="defaultProps"
          style="font-weight: bold"
        >
        </el-tree>
      </el-form-item>
    </el-row>

    <el-button type="primary" @click="GetReportTable">Продолжить</el-button>
    <custom-modal
      @addWasteToTable="addWasteToTable"
      title="Добавить отход"
      v-if="showModal === 'reportsAddWaste'"
      :visible="showModal === 'reportsAddWaste'"
    >
      <reports-add-waste-form
        :startDate="reportForm.startDate"
        :endDate="reportForm.endDate"
      ></reports-add-waste-form>
    </custom-modal>
  </el-form>
</template>

<script>
import ReportsAddWasteForm from "@/components/ecologist/reports/MainReport/reportsAddWasteForm";
import CustomModal from "@/components/customModal";
import { mapGetters } from "vuex";
import { AXIOS } from "@/AXIOS/http-common";
export default {
  name: "MainReport",
  components: { ReportsAddWasteForm, CustomModal },
  data() {
    return {
      isVisible: false,
      reportForm: {
        startDate: "",
        endDate: "",
        wastes: [],
        AllWaste: false,
      },
      actions: [
        {
          id: 0,
          name: "Все действия",
          children: [
            { id: 1, name: "Образовалось" },
            { id: 2, name: "Поступило" },
            { id: 3, name: "Передано" },
            { id: 4, name: "Обезврежено" },
            { id: 5, name: "Использовано" },
            { id: 6, name: "Захоронено" },
            { id: 7, name: "Прочее" },
            { id: 8, name: "Направлено на хранение" },
            {
              id: 9,
              name: "Фактическое количество отходов, находящихся на хранении",
            },
            { id: 10, name: "Передано контрагенту" },
          ],
        },
      ],
      organization: [
        {
          id: 1,
          name: "Предприятие",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },

  computed: {
    ...mapGetters({
      unitList: "GETUNITSLIST",
      showModal: "GETSHOWMODAL",
    }),
  },
  methods: {
    openAddWasteModal() {
      this.$store.dispatch("setShowModal", "reportsAddWaste");
    },

    addWasteToTable(waste) {
      this.reportForm.wastes.push(waste);
      this.hide();
    },
    dropLine(index) {
      this.reportForm.wastes.splice(index, 1);
    },

    setUnitsTree(u1, u2, u3) {
      let mainorg = 1;
      if (u1.length > 0) mainorg = u1[0].parentInfo.id;
      else if (u2.length > 0) mainorg = u2[0].parentInfo.id;
      else if (u3.length > 0) mainorg = u3[0].parentInfo.id;

      this.organization = [{ id: 1, name: "Предприятие", children: [] }];
      u1.forEach((elementu1) => {
        elementu1.children = [];
        u3.forEach((elementu3) => {
          elementu3.children = [];
          if (elementu3.parentInfo.id == elementu1.organizationId)
            elementu1.children.push({
              id: elementu3.organizationId,
              name: elementu3.title,
              children: elementu3.children,
            });
        });
        u2.forEach((elementu2) => {
          elementu2.children = [];
          u3.forEach((elementu3) => {
            elementu3.children = [];
            if (elementu3.parentInfo.id == elementu2.organizationId)
              elementu2.children.push({
                id: elementu3.organizationId,
                name: elementu3.title,
                children: elementu3.children,
              });
          });
          if (elementu2.parentInfo.id == elementu1.organizationId)
            elementu1.children.push({
              id: elementu2.organizationId,
              name: elementu2.title,
              children: elementu2.children,
            });
        });
        this.organization[0].children.push({
          id: elementu1.organizationId,
          name: elementu1.title,
          children: elementu1.children,
        });
      });

      u2.forEach((elementu2) => {
        elementu2.children = [];
        u3.forEach((elementu3) => {
          elementu3.children = [];
          if (elementu3.parentInfo.id == elementu2.organizationId)
            elementu2.children.push({
              id: elementu3.organizationId,
              name: elementu3.title,
              children: elementu3.children,
            });
        });
        if (elementu2.parentInfo.id == mainorg)
          this.organization[0].children.push({
            id: elementu2.organizationId,
            name: elementu2.title,
            children: elementu2.children,
          });
      });

      u3.forEach((elementu3) => {
        elementu3.children = [];
        if (elementu3.parentInfo.id == mainorg)
          this.organization[0].children.push({
            id: elementu3.organizationId,
            name: elementu3.title,
            children: elementu3.children,
          });
      });
    },

    async getWasteOrgs() {
      let u1;
      let u2;
      let u3;
      //let data = [{id: 1,name: "Предприятие",children: [],}];
      await AXIOS.get("user/organization/units/1/list?page=0&size=2000").then(
        (response) => {
          u1 = response.data.content;
          AXIOS.get("user/organization/units/2/list?page=0&size=2000").then(
            (response) => {
              u2 = response.data.content;
              AXIOS.get("user/organization/units/3/list?page=0&size=2000").then(
                (response) => {
                  u3 = response.data.content;
                  this.setUnitsTree(u1, u2, u3);
                }
              );
            }
          );
        }
      );
    },

    GetReportTable() {
      /*let date1 = new Date(this.reportForm.startDate);
          this.reportForm.startDate=date1;
          let date2 = new Date(this.reportForm.startDate);
          date2.setMonth(date1.getMonth()+1);
          this.reportForm.endDate=date2;
          console.log(this.reportForm.endDate);
          let data = {
            fileName: "Main-" + this.reportForm.date + this.reportForm.fileType,
            formData: this.reportForm,
          };
          console.log(this.reportForm);
          console.log(data);
          this.$store.dispatch("downloadMainReport", {data:data, reportName:"Main"});*/

      let forms = {
        StartDate: null,
        EndDate: null,
        AllWaste: false,
        Organization: [],
        Action: [],
        Wastes: [],
      };
      forms.StartDate = this.reportForm.startDate;
      forms.EndDate = this.reportForm.endDate;
      forms.AllWaste = this.reportForm.AllWaste;

      this.reportForm.wastes.forEach((wast) => {
        forms.Wastes.push(wast.ss01DictionaryId);
      });
      this.$refs.treeOrganization.getCheckedNodes().forEach((Org) => {
        forms.Organization.push(Org.id);
      });
      this.$refs.treeActions.getCheckedNodes().forEach((act) => {
        forms.Action.push(act.id);
      });
      this.$router.push({ path: "main-report-table", query: forms });
    },
    hide() {
      this.isVisible = true;
    },
  },
  mounted() {
    this.getWasteOrgs();
  },
};
</script>
