<template>
    <div>
      <main-report></main-report>
    </div>
  </template>
  
  <script>
  import MainReport from "@/components/ecologist/reports/MainReport/MainReport";
  export default {
    name: "MainReportView",
    components: { MainReport },
  };
  </script>
  
  <style scoped></style>